<template>
  <div id="newsStoryContent">
    <!-- picEBF1444EA367D97BBCD828D66418970B.jpg -->
    <img id="Image1" class="rounded img-large" src="@/assets/news-network/lu-at-e3.jpg">
    <p id="Text1">
      The LEGO® Universe team will be at E3 - the Electronic Entertainment Expo - in Los Angeles this week!
      E3 is a trade event for game industry professionals and isn’t open to the public, but we’ll share
      the exciting updates with you, right here at LEGOuniverse.com.
      <br><br>
      The first big piece of news is that we are partnering with Warner Bros. to bring LEGO Universe to
      retail stores this fall! We partnered with Warner Bros. for LEGO video games including LEGO® Star
      Wars and Harry Potter, and they will help us launch our first MMOG in stores throughout the US and
      Europe. E3 visitors will be able to play LEGO Universe in the Warner Bros. booth at E3.
      <br><br>
      We will reveal new LEGO Universe content – plus a few surprises – to journalists at E3, so check
      your favorite gaming and technology news sites for their impressions.
    </p>
  </div>
</template>
